<template>
    <div>
        <div class="main-title">文件列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">文件格式支持：PDF,DOC,DOCX</li>
                        <li data-v-69874ad0="">文件大小不能超过20M</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-select v-model="value" clearable placeholder="选择分类" size="small">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l" >
                    <el-input
                        size="small"
                        placeholder="文件名称"
                        v-model="fileName"
                        clearable>
                    </el-input>
                </span>


                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/file/add" class="router-link" >
                        <el-button type="primary" size="small" >上传文件</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <div class="file-list" v-loading="loading" >

                    <div class="file-item" v-for="(file,index) in files" :key="index">
                        <div class="file-item-top">
                            <span class="file-icon"><img src="../../../img/pdf.png"/> </span>
                            <div class="file-info">
                                <p class="file-info-title">{{file.name}}</p>
                                <p class="file-info-size">{{parseFloat((file.size/1024)/1024).toFixed(2) + 'M'}}</p>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="file-option">
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i class="el-icon-delete"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="重命名" placement="top">
                                <i class="el-icon-edit-outline"  v-on:click.stop="showSetName(file)">

                                    <el-popover
                                        placement="top"
                                        width="260"
                                        v-model="file.setName">
                                        <p class="set-name">
                                            <el-input size="mini" v-model="file.name" placeholder="请输入内容"></el-input>
                                        </p>
                                        <div style="text-align: right; margin: 0">
                                            <el-button size="mini" type="text"  v-on:click.stop="hideSetName(file)">取消</el-button>
                                            <el-button type="primary" size="mini"  v-on:click.stop="setName(file)" :loading="file.btnLoad">确定</el-button>
                                        </div>
                                    </el-popover>

                                </i>
                            </el-tooltip>

                        </div>
                    </div>
                </div>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                        small
                        background
                        :current-page="page"
                        :page-sizes="[10, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="setPageSize"
                        @current-change="setPage"
                        :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import {config} from "../../../js/config";

    export default {
        name: "list",
        data(){
            return {
                loading: true,
                visible: true,
                files:[],
                page:1,
                fileName: '',
                pageSize:10,
                total:0,
                imageUrl:config.imageUrl,
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                value: '',
                value2: '',
                tableData: [],
                currentRow: null
            }
        },
        methods:{
            ...mapActions('file',['getFileList','editFile']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({row, rowIndex}) {
                return '{padding:0}';
            },
            rowItem(){
                return 'row-item';
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    page:this.page,
                    pageSize:this.pageSize,
                }
                if(this.fileName!= '')
                {
                    data.name = this.fileName
                }
                this.getFileList(data).then(res => {
                    console.log(res)
                    this.loading = false
                    let data = [];
                    res.data.list.forEach(item => {
                        data.push({
                            id:item.id,
                            name:item.name,
                            path:item.path,
                            size:item.size,
                            status:item.status,
                            suffix:item.suffix,
                            user_id:item.user_id,
                            setName:false,
                            btnLoad:false,
                        })
                    })
                    this.total = res.data.total
                    this.files = data
                    this.loading = false
                })
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
            showSetName(file){
                file.setName = true
            },
            hideSetName(file)
            {
                file.setName = false
            },
            setName(file){

                let data = {
                    id:file.id,
                    name:file.name,
                }
                file.btnLoad = true
                this.editFile(data).then(res => {

                    if(res.ret == 0){
                        this.$message({
                            message: '修改成功！',
                            type: 'success'
                        });
                    }else{
                        this.$message({
                            message: res.res_info,
                            type: 'error'
                        });
                    }
                    this.hideSetName(file)
                    file.btnLoad = false
                })

            }
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>
.file-list{
    display: flex;
    flex-wrap:wrap;
}
.el-card__body{
    padding:10px
}
.file-item{
    margin-right: 10px;
    margin-bottom: 10px;
    width: 309px;
    border:1px solid #ebebeb;
}
.file-icon{
    float: left;
    width: 50px;
    height: 50px;
}
.file-info{
    float: left;
    margin-left:10px
}
.file-item-top{
    padding: 12px 10px 12px 12px;
}
.file-info-title{
    color: #333;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
}
.file-info-size{
    color: #999;
    font-size: 12px;
}
.file-option{
    height: 36px;
    line-height: 36px;
    padding:0 10px;
    background: #fafafa;
    border-top:1px solid #ebebeb;


}
.file-option .item{
    color: #aaaaaa;
    cursor: pointer;
    margin-right: 10px;
}
.clear{
    clear: both;
}
    .set-name{
        padding-bottom: 10px;
    }
</style>
